import { Formik, Form, FormikProps } from 'formik'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { BRANCH_TIMEZONE_OPTIONS, BRANCH_TYPES, CANADA_COUNTRY_ID, UNITED_STATES_COUNTRY_ID } from '../constants'
import { UserModel, UserPermissionLevel } from '../models/user'
import { IMiBranchModelDocument, IMetricsReportBranchGoalsDocument, IMiBranchPutModelDocument, IMiUserPostModelDocument, IMiUserPutModelDocument, IMiUsersDocument, IMiBranchesPostModelDocument } from '../open-api'
import { sortListByProperty } from '../services/helpers'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { SelectField, TextField, EmailField, DatePickerField, CheckboxField, NumberField, TinyMceField } from './forms'
import { Loading, LoadingPropsSizeEnum } from './loading'
import { Modal } from './modal'
import { UserForm } from './user-form'
import * as Yup from 'yup'
//import { GoogleMap } from './google-map'
import { FormikCountrySelectField } from './forms/formik-country-select-field'
import { FormikStateSelectField } from './forms/formik-state-select-field'
import { FormikColorPickerField } from './forms/formik-color-field'
import { FormikTextField } from './forms/formik-text-field'

export interface IBranchPostDocument {
	branchTypeId: number
	regionId: number
	branchName: string
	branchAbbr: string
	branchContact: number
	publicBranchName?: string
	address1?: string
	address2?: string
	city?: string
	stateId?: number
	countryId?: number
	postalCode?: string
	phone?: string
	fax?: string
	email?: string
	conferenceNumber?: string
	participantPin?: string
	presenterPin?: string
	dOpen?: string
	bUseAlternateAddress?: boolean
	altAddress1?: string
	altAddress2?: string
	altCity?: string
	altStateId?: number
	altPostalCode?: string
	//bProcessOwnGrants?: boolean
	bUseUserAddress?: boolean
	//bNoGranting?: boolean
	bShowImpactReport?: boolean
	bAllowGridExport?: boolean
	gracePeriodOverride?: string
	bConsultingSeesAll?: boolean
	isCauseSpecific?: boolean
	timezone?: string,
	branchColorPrimary: string,
	emailWelcomeSeries_1_1: string,
	emailWelcomeSeriesVideoUrl: string,
	emailWelcomeSeriesVideoThumbnailUrl: string,
	facebookUrl: string,
	linkedInUrl: string,
	instagramUrl: string,
}

interface ICommunityFormPropertiesProps {
	communityToEdit?: IMiBranchModelDocument
	onCommunitySave?: (community?: IMiBranchModelDocument) => Promise<void>
}

const defaultWelcomeMessage = `<p>Hi {{first_name}},</p>
<p>Welcome! If you're anything like me, you don't need another long email to read, so let me introduce myself and share some exciting benefits you will receive as a new member of our community!</p>
<p>My name is {{ad_first_name}} {{ad_last_name}} and I serve as Area Director of {{community_name}}. I have the privilege of equipping leaders like you in biblically-based fundraising and leadership practices through workshop events and coaching appointments. I've seen firsthand the transformative impact that can take place when leaders apply the training and tools we teach - not only for themselves, but also for the nonprofits they serve.</p>
<p>I look forward to meeting you! <strong>Look for an introductory video coming soon!</strong></p>`

export const CommunityProperties = (props: ICommunityFormPropertiesProps) => {
	const { communityToEdit, onCommunitySave } = props

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [newUserFormModal, showHideNewUserFormModal] = useModal()
	const [sampleWelcomEmailModal, showHideSampleWelcomEmailModal] = useModal()

	const initialValues = {
		branchTypeId: communityToEdit?.branchTypeId?.toString() || '',
		regionId: communityToEdit?.regionId?.toString() || '',
		branchName: communityToEdit?.branchName || '',
		branchAbbr: communityToEdit?.branchAbbr || '',
		publicBranchName: communityToEdit?.publicBranchName || '',
		branchContact: communityToEdit?.branchContact?.toString() || '',
		email: communityToEdit?.email || '',
		address1: communityToEdit?.address1 || '',
		address2: communityToEdit?.address2 || '',
		city: communityToEdit?.city || '',
		stateId: communityToEdit?.stateId?.toString() || '',
		stateOther: communityToEdit?.stateOther || '',
		countryId: communityToEdit?.countryId?.toString() || UNITED_STATES_COUNTRY_ID.toString(),
		postalCode: communityToEdit?.postalCode || '',
		phone: communityToEdit?.phone || '',
		fax: communityToEdit?.fax || '',
		conferenceNumber: communityToEdit?.conferenceNumber || '',
		participantPin: communityToEdit?.participantPin || '',
		presenterPin: communityToEdit?.presenterPin || '',
		altAddress1: communityToEdit?.altAddress1 || '',
		altAddress2: communityToEdit?.altAddress2 || '',
		altCity: communityToEdit?.altCity || '',
		altStateId: communityToEdit?.altStateId?.toString() || '',
		altPostalCode: communityToEdit?.altPostalCode || '',
		timezone: communityToEdit?.timezone || '',
		gracePeriodOverride: communityToEdit?.gracePeriodOverride ? moment(communityToEdit.gracePeriodOverride).format('MM/DD/YYYY') : '',
		dOpen: communityToEdit?.dOpen ? moment(communityToEdit?.dOpen).format('MM/DD/YYYY') : '',
		bUseAlternateAddress: communityToEdit ? !!communityToEdit.bUseAlternateAddress : '',
		bUseUserAddress: communityToEdit ? !!communityToEdit.bUseUserAddress : '',
		//bProcessOwnGrants: communityToEdit ? !!communityToEdit.bProcessOwnGrants : '',
		//bNoGranting: communityToEdit ? !!communityToEdit.bNoGranting : '',
		bShowImpactReport: communityToEdit ? !!communityToEdit.bShowImpactReport : '',
		bAllowGridExport: communityToEdit ? !!communityToEdit.bAllowGridExport : '',
		bConsultingSeesAll: communityToEdit ? !!communityToEdit.bConsultingSeesAll : '',
		isCauseSpecific: communityToEdit?.isCauseSpecific || '',
		branchColorPrimary: communityToEdit?.branchColorPrimary || '#008ba2',
		emailWelcomeSeries_1_1: communityToEdit?.emailWelcomeSeries_1_1 || defaultWelcomeMessage,
		emailWelcomeSeriesVideoUrl: communityToEdit?.emailWelcomeSeriesVideoUrl || '',
		emailWelcomeSeriesVideoThumbnailUrl: communityToEdit?.emailWelcomeSeriesVideoThumbnailUrl || '',
		facebookUrl: communityToEdit?.facebookUrl || '',
		linkedInUrl: communityToEdit?.linkedInUrl || '',
		instagramUrl: communityToEdit?.instagramUrl || '',
	}

	const validationSchema = Yup.object({
		branchTypeId: Yup.string().required('Required'),
		regionId: Yup.string().required('Required'),
		branchName: Yup.string().required('Required'),
		branchAbbr: Yup.string().required('Required').max(4, 'Community abbreviations cannot be more than 4 characters.'),
		branchContact: Yup.string().required('Required'),
		countryId: Yup.string().required('Required'),
		timezone: Yup.string().required('Required'),
		emailWelcomeSeriesVideoUrl: Yup.string().matches(/^https:\/\//, 'Please enter a valid URL. Must start with https://'),
		emailWelcomeSeriesVideoThumbnailUrl: Yup.string().matches(/^https:\/\//, 'Please enter a valid URL. Must start with https://'),
		facebookUrl: Yup.string().matches(/^https:\/\//, 'Please enter a valid URL. Must start with https://'),
		linkedInUrl: Yup.string().matches(/^https:\/\//, 'Please enter a valid URL. Must start with https://'),
		instagramUrl: Yup.string().matches(/^https:\/\//, 'Please enter a valid URL. Must start with https://'),
	})

	const [defaultGoals, setDefaultGoals] = useState<IMetricsReportBranchGoalsDocument>()
	const [newUserDocument, setNewUserDocument] = useState<IMiUserPostModelDocument>()
	const [showWelcomeSeries, setShowWelcomeSeries] = useState<boolean>(false)

	let _formikProps: FormikProps<any>

	useEffect(() => {
		// We are creating a Community - we need to create it with default goals.
		if (!communityToEdit) {
			appActions.MetricsReportBranchGoalsApi.apiMetricsReportBranchGoalsGet()
				.then(results => setDefaultGoals(results.data))
		}

		if (!appState.users) appActions.fetchMiUsers()

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (_formikProps) _formikProps.resetForm()
		// eslint-disable-next-line
	}, [communityToEdit])


	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={validationSchema}
			onSubmit={async (values, actions) => {
				console.log('submitted')
				const communityDocument: IMiBranchesPostModelDocument = {
					branchTypeId: parseInt(values.branchTypeId),
					regionId: parseInt(values.regionId),
					branchName: values.branchName,
					branchAbbr: values.branchAbbr,
					branchContact: parseInt(values.branchContact),
					publicBranchName: values.publicBranchName.length ? values.publicBranchName : null,
					address1: values.address1.length ? values.address1 : null,
					address2: values.address2.length ? values.address2 : null,
					city: values.city.length ? values.city : null,
					stateId: parseInt(values.stateId),
					countryId: parseInt(values.countryId),
					stateOther: values.stateOther,
					postalCode: values.postalCode.length ? values.postalCode : null,
					phone: values.phone.length ? values.phone : null,
					fax: values.fax.length ? values.fax : null,
					email: values.email.length ? values.email : null,
					conferenceNumber: values.conferenceNumber.length ? values.conferenceNumber : null,
					participantPin: values.participantPin.toString().length ? values.participantPin.toString() : null,
					presenterPin: values.presenterPin.toString().length ? values.presenterPin.toString() : null,
					dOpen: values.dOpen.length ? values.dOpen : null,
					bUseAlternateAddress: typeof values.bUseAlternateAddress === 'boolean' ? values.bUseAlternateAddress : null,
					altAddress1: values.altAddress1.length ? values.altAddress1 : null,
					altAddress2: values.altAddress2.length ? values.altAddress2 : null,
					altCity: values.altCity.length ? values.altCity : null,
					altStateId: values.altStateId.length ? parseInt(values.altStateId) : null,
					altPostalCode: values.altPostalCode.length ? values.altPostalCode : null,
					//bProcessOwnGrants: typeof values.bProcessOwnGrants === 'boolean' ? values.bProcessOwnGrants : null,
					bUseUserAddress: typeof values.bUseUserAddress === 'boolean' ? values.bUseUserAddress : null,
					//bNoGranting: typeof values.bNoGranting === 'boolean' ? values.bNoGranting : null,
					bShowImpactReport: typeof values.bShowImpactReport === 'boolean' ? values.bShowImpactReport : null,
					bAllowGridExport: typeof values.bAllowGridExport === 'boolean' ? values.bAllowGridExport : null,
					gracePeriodOverride: values.gracePeriodOverride.length ? values.gracePeriodOverride : null,
					bConsultingSeesAll: typeof values.bConsultingSeesAll === 'boolean' ? values.bConsultingSeesAll : null,
					isCauseSpecific: typeof values.isCauseSpecific === 'boolean' ? values.isCauseSpecific : null,
					timezone: values.timezone.length ? values.timezone : null,
					createDefaultTrainingArea: false,
					branchColorPrimary: values.branchColorPrimary.length ? values.branchColorPrimary : null,
					emailWelcomeSeries_1_1: values.emailWelcomeSeries_1_1.length && values.emailWelcomeSeries_1_1 !== defaultWelcomeMessage ? values.emailWelcomeSeries_1_1 : null,
					emailWelcomeSeriesVideoUrl: values.emailWelcomeSeriesVideoUrl.length ? values.emailWelcomeSeriesVideoUrl : null,
					emailWelcomeSeriesVideoThumbnailUrl: values.emailWelcomeSeriesVideoThumbnailUrl.length ? values.emailWelcomeSeriesVideoThumbnailUrl : null,
					facebookUrl: values.facebookUrl.length ? values.facebookUrl : null,
					linkedInUrl: values.linkedInUrl.length ? values.linkedInUrl : null,
					instagramUrl: values.instagramUrl.length ? values.instagramUrl : null,
				}

				if (!!communityToEdit) {
					const communityToEditDocument: IMiBranchPutModelDocument = {
						...communityToEdit,
						...communityDocument
					}

					await makeHttpRequestWithUi({
						request: appActions.MiBranchesApi.apiMiBranchesIdPut(communityToEdit.branchId, communityToEditDocument),
						toastSuccessMessage: 'Successfully updated the community',
						toastErrorMessage: 'There was an error updating the community.'
					})

					if (onCommunitySave) onCommunitySave()
				} else {
					/* 
							If a new user was created while creating the community, go ahead and actually create the user in the backend IF the user hasn't changed their selection.
					*/
					let newUser: IMiUsersDocument | null = null
					if (newUserDocument && values.branchContact === newUserDocument.email) {
						/* 
								We can't create a new user with a branchId. 
								So we start with a placeholder of 0, then updated it to the new Community ID once it has been created.
						*/
						newUserDocument.branchId = 0

						newUser = (await makeHttpRequestWithUi({
							request: appActions.MiUsersApi.apiMiUsersPost(newUserDocument),
							toastErrorMessage: 'There was an error creating the user.',
							disableSuccessToast: true,
						})).data

						communityDocument.branchContact = newUser.userId
					}

					const createDefaultTrainingArea = true

					const newCommunityQuery = await makeHttpRequestWithUi({
						request: appActions.MiBranchesApi.apiMiBranchesPost({ ...communityDocument, createDefaultTrainingArea }),
						toastSuccessMessage: 'Successfully created community',
						toastErrorMessage: 'There was an error creating the community.',
					})

					/* 
							Now that the community is created, if we created a user we need to update it to have the new community's branch ID.
					*/
					if (newUser && newUserDocument) {
						const editedUser: IMiUserPutModelDocument = { ...newUserDocument, branchId: newCommunityQuery.data.branchId, userId: newUser.userId }

						await makeHttpRequestWithUi({
							request: appActions.MiUsersApi.apiMiUsersIdPut(newUser.userId, editedUser),
							toastErrorMessage: `There was an error updating the new user's community to the newly created community.`,
							disableSuccessToast: true,
						})
					}


					/* 
							Set up the new community's default goals.
					*/
					await makeHttpRequestWithUi({
						request: appActions.MetricsReportBranchGoalsApi.apiMetricsReportBranchGoalsPost({
							branchId: newCommunityQuery.data.branchId,
							level1Involvement: defaultGoals!.level1Involvement!,
							level2Involvement: defaultGoals!.level2Involvement!,
							level3Involvement: defaultGoals!.level3Involvement!,
							activeMinistries: defaultGoals!.activeMinistries!,
							coachingMinistries: defaultGoals!.coachingMinistries!,
							consultingMinistries: defaultGoals!.consultingMinistries!,
							trWorkshopAttendance: defaultGoals!.trWorkshopAttendance!,
							trSeminarAttendance: defaultGoals!.trSeminarAttendance!,
							trGroupCoaching: defaultGoals!.trGroupCoaching!,
							trCoaching: defaultGoals!.trCoaching!,
							trConsulting: defaultGoals!.trConsulting!,
							trTotal: defaultGoals!.trTotal!,
							coachingHoursPerQuarterCap: defaultGoals!.coachingHoursPerQuarterCap!,
							consultingHoursPerQuarterCap: defaultGoals!.consultingHoursPerQuarterCap!,
						}),
						disableSuccessToast: true,
						toastErrorMessage: 'There was an error creating default goals for this community.'
					})

					if (onCommunitySave) onCommunitySave(newCommunityQuery.data)
				}
			}}
		>
			{formikProps => {
				_formikProps = formikProps

				return (
					<Form className='d-flex flex-column mx-2' style={{ height: '100%' }}>
						<div style={{ height: '100%' }} className='d-flex flex-column'>
							<div className='d-flex p-1' style={{ flex: 1 }}>
								{/* Left Column */}
								<div className='d-flex flex-column pr-3 border-right pt-3' style={{ flex: 1 }}>
									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><SelectField 
											fieldProps={{ name: 'branchTypeId', label: 'Community Type', placeholder: 'Select a type...', labelTooltip: 'Private = Communities start out as "private"; Public = Community is live; Facilitated = Communities that are no longer actively led by an AD; Suspended = Non-AD-led community that is not able to be "facilitated".' }} 
											options={BRANCH_TYPES} /></div>
										<div style={{ flex: 1 }}><SelectField fieldProps={{ name: 'regionId', label: 'Region', placeholder: 'Select a region...', labelTooltip:'Administrative and International communities are excluded from being automatically assigned as a ministry\'s primary community when they register.' }} options={sortListByProperty(appState.branchRegions, 'name').filter(o => o.bActive).map(o => ({ label: `${o.name}`, value: `${o.id}` }))} /></div>
									</div>

									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'branchName', label: 'Community Name' }} /></div>
										<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'branchAbbr', label: 'Community Abbreviation' }} /></div>
										{/* <div style={{ flex: 1 }}><TextField fieldProps={{ name: 'publicBranchName', label: 'Public Name', labeltooltip: 'This is what will be displayed on the public site in place of the internal Community name.' }} /></div> */}
									</div>

									<div className='d-flex align-items-center'>
										<div className='mr-2' style={{ flex: 1 }}><EmailField fieldProps={{ name: 'email', label: 'Email' }} /></div>
										<div className='mr-2' style={{ flex: 1 }}>
											{/* 
													This select field is a bit tricky.
													Since users can create a contact to be included on the new community,
													we need to add that freshly created contact to the list of potential contacts (if they make one).

													However, we don't actually create the contact in the backend until the user creates the community.
													That way we don't end up with any orphaned users that were created while starting a community.

													Since we don't create the contact immediately, we need to "fake" add it to the list of
													users so that it will show up in the dropdown.

													We use the email field on the contact as a proxy for its ID (since it won't have an ID until it's created).

													That way we can know if the user changes the contact selection away from the newly created contact
													(in which case we will not actually persist it to the backend).
											*/}
											{appState.users ?
												<SelectField
													fieldProps={{ name: 'branchContact', label: 'Community Contact', placeholder: 'Select a contact...' }}
													options={
														[
															...sortListByProperty(appState.users, 'firstName').filter(o => !o.bDisabled || o.userId === 40),
															...!!newUserDocument ? [{ userId: newUserDocument.email, firstName: newUserDocument.firstName, lastName: newUserDocument.lastName }] : []
														].map(o => ({ value: `${o.userId}`, label: `${o.firstName || ''} ${o.lastName || ''}` }))
													}
												/>
												:
												<Loading size={LoadingPropsSizeEnum.small} />
											}
										</div>
										{!!communityToEdit ?
											null
											:
											<div className='mr-2'>
												<span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => showHideNewUserFormModal(true)}>
													{/* We don't clear the new user form, so it's possible to modify the newly created contact until the whole Community is saved. */}
													{!!newUserDocument ? 'Modify New Contact' : 'Create New Contact'}
												</span>
											</div>
										}
									</div>

									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><DatePickerField fieldProps={{ name: 'dOpen', label: 'Date Opened', placeholder: ' ' }} /></div>
										{UserModel.checkPermissionLevelForUser(1, UserPermissionLevel.Administrator, appState.currentUser) ?
											<div className='mr-2' style={{ flex: 1 }}><DatePickerField fieldProps={{ name: 'gracePeriodOverride', label: 'Grace Period Overide', placeholder: ' ', labeltooltip: 'Temporary Grace period; overrides INC status for Community.' }} /></div>
											:
											null
										}
										<div style={{ flex: 1 }}><SelectField fieldProps={{ name: 'timezone', label: 'Time Zone' }} options={BRANCH_TIMEZONE_OPTIONS} /></div>
									</div>
									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><FormikTextField field={{ name: 'facebookUrl', label: 'Facebook URL' }} labelTooltip='The URL to the Facebook page for the Community. Currently only displays in the Welcome Series email.' /></div>
										<div className='mr-2' style={{ flex: 1 }}><FormikTextField field={{ name: 'instagramUrl', label: 'Instagram URL' }} labelTooltip='The URL to the Instagram page for the Community. Currently only displays in the Welcome Series email.' /></div>
										<div style={{ flex: 1 }}><FormikTextField field={{ name: 'linkedInUrl', label: 'LinkedIn URL' }} labelTooltip='The URL to the LinkedIn page for the Community. Currently only displays in the Welcome Series email.' /></div>
									</div>

									<div className='d-flex flex-column'>
										<div className='mr-3'><CheckboxField fieldProps={{ name: 'isCauseSpecific', label: 'Cause Specific' }} /></div>
										{/* TB 20230421 - Trent said we can stop using bProcessOwnGrants or bNoGranting since we don't know why they exist. */}
										{/* <div className='mr-3'><CheckboxField fieldProps={{ name: 'bProcessOwnGrants', label: 'Processes Grants' }} /></div> */}
										{/* <div className='mr-3'><CheckboxField fieldProps={{ name: 'bNoGranting', label: 'Disable New Grants' }} /></div> */}
										<div className='mr-3'><CheckboxField fieldProps={{ name: 'bShowImpactReport', label: 'Impact Report', labeltooltip: 'Check this box to show the full impact report on the public site. If unchecked, only quotes and the ministry map will be displayed.' }} /></div>
										<div className='mr-3'><CheckboxField fieldProps={{ name: 'bAllowGridExport', label: 'Allow Grid Export', labeltooltip: 'Check this box to allow users of this branch to export data out of grids.' }} /></div>
										<div className='mr-3'><CheckboxField fieldProps={{ name: 'bConsultingSeesAll', label: 'Consulting Sees All', labeltooltip: 'This switch allows consulting-level ministries to see coaching calendars of Area Directors who are marked for coaching only.' }} /></div>
										<div className='w-25'><FormikColorPickerField field={{ name: 'branchColorPrimary', label: 'Primary Community Color' }} labelTooltip='What is the primary color of this community? Used to style some of the font in the Welcome Series.' /></div>
									</div>
									<div className='d-flex flex-column'>
										{!showWelcomeSeries && <div><button className='btn btn-secondary' onClick={() => setShowWelcomeSeries(true)}>Edit Welcome Series</button></div>}
										{showWelcomeSeries &&  
										<div className="card">
											<div className="card-header">
												Welcome Series Configuration
											</div>
											<div className="card-body">
												<p>When a Ministry Contact is added, we will send them a series of welcome emails. You may customize the welcome message in the first email of the series by entering text below. <a href='#' onClick={() => showHideSampleWelcomEmailModal(true)}>See sample email.</a></p>
												<TinyMceField fieldProps={{name: 'emailWelcomeSeries_1_1', label: 'Welcome Series - Email 1 - Welcome Message' }}  />

												<div><FormikTextField field={{ name: 'emailWelcomeSeriesVideoUrl', label: 'Welcome Series - Email 2 - Video URL' }} labelTooltip='The URL of a video to display in the second email of the welcome series.' /></div>
												<div><FormikTextField field={{ name: 'emailWelcomeSeriesVideoThumbnailUrl', label: 'Welcome Series - Email 2 - Video Thumbnail URL' }} labelTooltip='The URL of a video thumbnail image to display in the second email of the welcome series.' /></div>
											</div>
										</div> }
									</div>
								</div>

								{/* Right Column */}
								<div className='d-flex flex-column pl-3 pt-3' style={{ height: '100%' }}>
									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'address1', label: 'Address' }} /></div>
										<div style={{ flex: 1 }}><TextField fieldProps={{ name: 'address2', label: 'Address Line 2' }} /></div>
									</div>

									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }} ><TextField fieldProps={{ name: 'city', label: 'City' }} /></div>
										<div style={{ flex: 1 }} ><TextField fieldProps={{ name: 'postalCode', label: 'Postal Code' }} disabled={!formikProps.values.countryId} /></div>
									</div>

									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><FormikCountrySelectField field={{ name: 'countryId', label: 'Country', placeholder: 'Select a country' }} /></div>
										<div style={{ flex: 1 }}>
										{(_formikProps.values.countryId == UNITED_STATES_COUNTRY_ID || _formikProps.values.countryId == CANADA_COUNTRY_ID) ? 
										<FormikStateSelectField 
											field={{ name: 'stateId', label: 'State', placeholder: 'Select a state', disabled: !_formikProps.values.countryId }}
											countryId={_formikProps.values.countryId} />
										: 
										<TextField 
											fieldProps={{ name: 'stateOther', label: 'State/Province' }}  
											disabled={!_formikProps.values.countryId} /> }
										</div>
									</div>


									{/* {formikProps.values.address1 && formikProps.values.city && formikProps.values.stateId ?
										<React.Fragment>
											<div className='mb-4' style={{ height: 200 }}>
												<GoogleMap address1={formikProps.values.address1} city={formikProps.values.city} stateId={formikProps.values.stateId} />
											</div>
										</React.Fragment>
										:
										null
									} */}

									{/* 20230804 TB - Don't see that bUseUserAddress or bUseAlternateAddress is actually being used.
									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><CheckboxField fieldProps={{ name: 'bUseUserAddress', label: 'Use User Address on Public Site' }} /></div>
										<div style={{ flex: 1 }}><CheckboxField fieldProps={{ name: 'bUseAlternateAddress', label: 'Use Alt Address on Public Site' }} /></div>
									</div> */}


									{/* {formikProps.values.bUseAlternateAddress ?
										<div className='animated fadeInDown' style={{ animationDuration: '200ms' }}>
											<div className='d-flex'>
												<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'altAddress1', label: 'Address' }} /></div>
												<div style={{ flex: 1 }}><TextField fieldProps={{ name: 'altAddress2', label: 'Address Line 2' }} /></div>
											</div>

											<div className='d-flex'>
												<div className='mr-2' style={{ flex: 1 }} ><TextField fieldProps={{ name: 'altCity', label: 'City' }} /></div>
												<div className='mr-2' style={{ flex: 1 }}>{formikProps.values.countryId === UNITED_STATES_COUNTRY_ID.toString() ? <StateSelectField fieldProps={{ name: 'altStateId', label: 'State', placeholder: 'Select a state...' }} /> : null}</div>
												<div style={{ flex: 1 }} ><ZipField fieldProps={{ name: 'altPostalCode', label: 'Zip' }} /></div>
											</div>
										</div>
										:
										null
									} */}

									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'phone', label: 'Phone' }} /></div>
										<div style={{ flex: 1 }}><TextField fieldProps={{ name: 'fax', label: 'Fax' }} /></div>
									</div>

									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'conferenceNumber', label: 'Conference Number' }} /></div>
										<div className='mr-2' style={{ flex: 1 }}><NumberField noFormat={true} fieldProps={{ name: 'presenterPin', label: 'Presenter Pin' }} /></div>
										<div style={{ flex: 1 }}><NumberField noFormat={true} fieldProps={{ name: 'participantPin', label: 'Participant Pin' }} /></div>
									</div>

								</div>
							</div>

							<div className='d-flex my-4'>
								<button disabled={!defaultGoals && !communityToEdit} type='submit' className='btn btn-primary btn-large' style={{ minWidth: 300 }}>{!!communityToEdit ? 'Update Properties' : 'Create Community'}</button>
							</div>
						</div>

						<Modal
							{...newUserFormModal}
							modalTitle='New User'
							size='xxl'
							_onModalShown={() => {
								// When the user form is shown, load the app permissions (if not already present in the global state)
								if (!appState.appPermissions) appActions.fetchAppPermissions()
							}}
						>
							{appState.appPermissions ?
								<div className='mb-2'>
									<UserForm appPermissions={appState.appPermissions} fromNewBranch={(_newUserDocument) => {
										setNewUserDocument(_newUserDocument)
										showHideNewUserFormModal(false)

										/* 
												Set the selected contact value to the newly created contact's email address (a required property in the <UserForm />).
												We use this as a proxy for the user ID.
												Setting the branchContact field value to the newly created user's email will be used to show this new user as selected in the branchContact <SelectField />.
										*/
										formikProps.setFieldValue('branchContact', _newUserDocument.email)
									}} />
								</div>
								:
								<Loading />
							}
						</Modal>

						<Modal
							{...sampleWelcomEmailModal}
							modalTitle='Sample Welcome Email'
							size='lg'
						>
							<img src="https://mi-main-prod.s3.amazonaws.com/wp-content/uploads/20241219134627/Omt1InZy29.png" style={{ width: '618px', height: '1741px' }} />
						</Modal>
					</Form>
				)
			}}
		</Formik>
	)
}
import { EventEvals } from "./event-evals"
import { useContext, useEffect } from "react"
import { MinistryInfoContext } from "./ministry-info"
import { Tabs } from "./tabs"
import { EVENT_TYPES } from "../constants"
import { Loading } from "./loading"
import { EventEvals2 } from "./event-evals2"

export const MinistryInfoEventEvals = () => {
    const { state: ministryInfoState } = useContext(MinistryInfoContext)!

    // useEffect(() => {
	// 	ministryInfoActions.fetchEventEvals()
	// 	// eslint-disable-next-line
	// }, [])

    if (!ministryInfoState.ministry) return <Loading />

    return (
        <>
            <Tabs
                tablListClassName='my-2 ml-2'
                // controls={{
                //     activeTab,
                //     setActiveTab
                // }}
                tabs={[
                    {
                        id: 'nonWebinars',
                        title: 'Non-Webinars',
                        content: <EventEvals2 ministryId={ministryInfoState.ministry.ministryId} eventTypeId={[EVENT_TYPES.GroupLearning, EVENT_TYPES.Workshop]} /> //<EventEvals evals={ministryInfoState.ministryEventEvals?.filter(x => x.eventTypeId !== EVENT_TYPES.Webinar) || []} />
                    },
                    {
                        id: 'webinars',
                        title: 'Webinars',
                        content: <EventEvals2 ministryId={ministryInfoState.ministry.ministryId} eventTypeId={[EVENT_TYPES.Webinar]} />
                    }
                ]}
            />
        </>
    )
}
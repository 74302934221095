import { IField } from "./types"
import clsx from 'clsx'
import { useField } from 'formik'
import React, { useRef } from "react"
import { useFormikScrollToError } from "../../hooks"
import { TooltipInfo } from '../partials'

interface IFormikTextAreaFieldProps {
    field: IField,
    customStyles?: React.CSSProperties
}

export const FormikTextAreaField = ({ field, customStyles }: IFormikTextAreaFieldProps) => {

    const [formikField, { error, touched }] = useField(field)

    const showInvalid = error && touched

    const fieldRef = useRef<HTMLInputElement>(null)
    useFormikScrollToError({ field: fieldRef, name: formikField.name })

    return (
        <div className={clsx('form-group', showInvalid && 'is-invalid')} >
            <label htmlFor={formikField.name} >
                {field.label}
                {field.labelTooltip ? <TooltipInfo style={{ marginBottom: 4, marginLeft: 4 }} tooltipText={field.labelTooltip} /> : ''}
            </label>

            <textarea
                {...formikField}
                {...field}
                className={clsx('form-control', showInvalid && 'is-invalid')}
                placeholder={field.placeholder}
                disabled={field.disabled}
                style={{ ...customStyles, flex: 1 }}
            />

            <div className='invalid-feedback' style={{ height: '19.4px', display: 'block' }} >{showInvalid && error}</div>
        </div>
    )
}
import { ISearchRequestFilterDocument, ISearchSortDocument, ISeminarEvalSummaryDocument } from "../open-api"
import { useCallback, useContext, useState } from "react"
//import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { GridDataFetch, IGridColumn, IGridListItem, IGridState } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { EVENT_EVAL_RATINGS_WEBINAR, EVENT_EVAL_RATINGS_WORKSHOP, EVENT_TYPES } from "../constants"
import { DefaultGridCellDisplay } from "./grid-cell-displays"
import { Grid } from "./grid"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IAppState } from "../stores/app-definitions"
import { getOrCreateFilterForColumnPropertyAndOperator } from "./grid-column-filter"

interface IEventEvals2 {
    eventId?: number
    ministryId?: number
    eventTypeId?: EVENT_TYPES[]
}

export const EventEvals2 = (props: IEventEvals2) => {
    const { eventId, ministryId, eventTypeId } = props
    console.log('EventEvals2 > eventId', eventId)
    const appState = useContext(AppStateContext)
    const { SeminarEvalApi } = useContext(AppActionContext)! 

    //const [evals, setEvals] = useState<ISeminarEvalSummaryDocument[] | undefined>(undefined)
    const [areWebinars, setAreWebinars] = useState<boolean>(false)

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    
    const dataSource: GridDataFetch<IAppState> = useCallback(async (queryState, _appState) => {
        // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
        const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as unknown as ISearchRequestFilterDocument[] | undefined
        const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as unknown as ISearchSortDocument[] | undefined

        try {

            const query = await makeHttpRequestWithUi({
                request: SeminarEvalApi.apiSeminarEvalGet(
                    (queryState.page - 1) * queryState.itemsPerPage,
                    queryState.itemsPerPage,
                    sorts,
                    filters
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the list of grant requests.'
            })

            const rows = (query.data.data || []).map<IGridListItem>(item => ({
                id: item.seminarEvalId.toString(),
                values: {
                    ...item
                }
            }))

            console.log('EventEvals2 > dataSource > rows', rows)

            // The assumption is that the results should always be of the same event type
            if (rows.length > 0) {
                setAreWebinars(rows[0].values.eventType === EVENT_TYPES.Webinar)
            }

            return {
                rows,
                count: query.data.totalCount
            }
        } catch (e) {
            return {
                count: 0,
                rows: []
            }
        }
    }, [])

    let gridColumns: IGridColumn[] = []
    let gridColumnsWebinar: IGridColumn[] = []

    gridColumns.push(
        {
            property: 'ministryName',
            type: 'string',
            width: 125,
            allowFilters: false,
            title: 'Ministry',
            render: (col, row) => <a target='_blank' href={`/ministry-info/${row.values.ministryId}`}>{row.values[col.property]}</a>
        },
        {
            property: 'ministryBranchAbbr',
            type: 'string',
            width: 125,
            allowFilters: false,
            title: 'Ministry Community',
            render: DefaultGridCellDisplay,
            filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
            hide: true
        },
        {
            property: 'attendeeName',
            type: 'string',
            width: 130,
            allowFilters: false,
            title: 'Attendee Name',
            render: DefaultGridCellDisplay,
        },
        {
            property: 'attendeeEmail',
            type: 'string',
            width: 130,
            allowFilters: false,
            title: 'Attendee Email',
            render: DefaultGridCellDisplay,
        },
        {
            property: 'attendeePhone',
            type: 'string',
            width: 130,
            allowFilters: false,
            title: 'Attendee Phone',
            render: DefaultGridCellDisplay,
            hide: true
        },
        {
            property: 'mostHelpful',
            type: 'string',
            width: 150,
            allowFilters: false,
            title: 'Most Helpful',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.mostHelpful === 'string' ? row.values.mostHelpful : undefined
        },
        {
            property: 'leastHelpful',
            type: 'string',
            width: 150,
            allowFilters: false,
            title: 'How to Improve',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.leastHelpful === 'string' ? row.values.leastHelpful : undefined
        },
        {
            property: 'changeAfterTraining',
            type: 'string',
            width: 150,
            allowFilters: false,
            title: 'Biggest Take-Away',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.changeAfterTraining === 'string' ? row.values.changeAfterTraining : undefined
        },
        {
            property: 'comments',
            type: 'string',
            width: 150,
            allowFilters: false,
            title: 'Comments',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.comments === 'string' ? row.values.comments : undefined
        },
        {
            property: 'value',
            type: 'string',
            width: 72,
            allowFilters: false,
            title: 'Value',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'content',
            type: 'string',
            width: 72,
            allowFilters: false,
            title: 'Content',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'speakers',
            type: 'string',
            width: 72,
            allowFilters: false,
            title: 'Speakers',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'facility',
            type: 'string',
            width: 72,
            allowFilters: false,
            title: 'Facility',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
    )

    gridColumnsWebinar.push(
        {
            property: 'ministryName',
            type: 'string',
            width: 125,
            allowFilters: false,
            title: 'Ministry',
            render: (col, row) => <a target='_blank' href={`/ministry-info/${row.values.ministryId}`}>{row.values[col.property]}</a>
        },
        {
            property: 'ministryBranchAbbr',
            type: 'string',
            width: 125,
            allowFilters: false,
            title: 'Ministry Community',
            render: DefaultGridCellDisplay,
            filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
            hide: true
        },
        {
            property: 'attendeeName',
            type: 'string',
            width: 130,
            allowFilters: false,
            title: 'Attendee Name',
            render: DefaultGridCellDisplay,
        },
        {
            property: 'attendeeEmail',
            type: 'string',
            width: 130,
            allowFilters: false,
            title: 'Attendee Email',
            render: DefaultGridCellDisplay,
        },
        {
            property: 'attendeePhone',
            type: 'string',
            width: 130,
            allowFilters: false,
            title: 'Attendee Phone',
            render: DefaultGridCellDisplay,
            hide: true
        },
        {
            property: 'mostHelpful',
            type: 'string',
            width: 150,
            allowFilters: false,
            title: 'Most Helpful',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.mostHelpful === 'string' ? row.values.mostHelpful : undefined
        },
        {
            property: 'leastHelpful',
            type: 'string',
            width: 150,
            allowFilters: false,
            title: 'Least Helpful',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.leastHelpful === 'string' ? row.values.leastHelpful : undefined
        },
        {
            property: 'changeAfterTraining',
            type: 'string',
            width: 150,
            allowFilters: false,
            title: 'Change After Training',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.changeAfterTraining === 'string' ? row.values.changeAfterTraining : undefined
        },
        {
            property: 'comments',
            type: 'string',
            width: 150,
            allowFilters: false,
            title: 'Comments',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.comments === 'string' ? row.values.comments : undefined
        },
        {
            property: 'webinarValue',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Value',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
            
        },
        {
            property: 'webinarContent',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Content',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarSpeakers',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Speakers',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarContentActionable',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Content Actionable',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarEffective',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Effective',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarInvolveStaff',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Involve Staff',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarMoreWebinars',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'More Webinars',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarRegistrationEase',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Registration Ease',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarWellManaged',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Well Managed',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarQuestionAndAnswer',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Q And A',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarAccess',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Access',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarConfirmed',
            type: 'string',
            width: 83,
            allowFilters: false,
            title: 'Confirmed',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarAttendAgain',
            type: 'boolean',
            width: 83,
            allowFilters: false,
            title: 'Attend Again',
            render: DefaultGridCellDisplay
        }
    )

    const initialGridState: IGridState = {
        ...defaultGridState,
        queryState: {
            ...defaultGridState.queryState,
            filters: [ {
                ...getOrCreateFilterForColumnPropertyAndOperator([], 'eventId', 'eq'),
                enabled: !!eventId,
                value: eventId 
            },
            {
                ...getOrCreateFilterForColumnPropertyAndOperator([], 'ministryId', 'eq'),
                enabled: !!ministryId,
                value: ministryId 
            },
            {
                ...getOrCreateFilterForColumnPropertyAndOperator([], 'eventTypeId', 'in'),
                enabled: !!eventTypeId,
                value: eventTypeId ? eventTypeId.map(x => x.toString()) : [] 
            }]
        },
        dataSource,
        columns: areWebinars ? gridColumnsWebinar : gridColumns,
    }

    const [gridState, gridActions] = useGrid(gridReducer, 
        initialGridState, 
        [])

    return (
        <>
            <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
            </div>
        </>
    )
}
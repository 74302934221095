import { Tabs } from "./tabs"
import { EventFormGeneral } from "./event-form-general"
import { EventStatus, EventFormTab, useEventFormContext } from "./event-form"
import { EventFormRegistrants } from './event-form-registrants'
import { EventFormEvals } from './event-form-evals'
import { EventFormCourses } from "./event-form-courses"
import { useEffect, useState } from "react"

export const EventFormTabs = () => {

    const { activeTab, setState, eventToEdit, eventContent, fetchEvals } = useEventFormContext()

    const [lmsLoaded, setLmsLoaded] = useState<boolean>(false)

    // This makes it so that the LMS tab content only renders once
    useEffect(() => {
        if (activeTab === EventFormTab.lms) setLmsLoaded(true);
        if (activeTab === EventFormTab.evals) fetchEvals();
    }, [activeTab])

    return (
        <>

            <Tabs
                keepTabContentInDomWhenNotActive
                controls={{
                    activeTab: activeTab,
                    setActiveTab: tab => setState(_state => ({ ..._state, activeTab: tab }))
                }}
                tabs={[
                    {
                        id: EventFormTab.general,
                        title: 'Summary',
                        content: <EventFormGeneral />
                    },
                    ...eventToEdit && eventToEdit.status !== EventStatus.draft ?
                        [{
                            id: EventFormTab.registrants,
                            title: 'Registrants',
                            content:
                                <EventFormRegistrants
                                    eventToEdit={eventToEdit}
                                    eventContentTitle={eventContent.title}
                                    isGroupLearning={false}
                                    hasLmsCourses={Boolean(eventToEdit.eventContent.courseIdList && eventToEdit.eventContent.courseIdList.length > 0)}
                                 />
                        },
                        {
                            id: EventFormTab.evals,
                            title: 'Eval Results',
                            content: activeTab === EventFormTab.evals ? <EventFormEvals /> : <></>
                        },
                        ] : [],
                    ...eventToEdit && eventToEdit.status !== EventStatus.draft && eventToEdit.eventContent && eventToEdit.eventContent.courseIdList && eventToEdit.eventContent.courseIdList.length > 0 ?
                        [{
                            id: EventFormTab.lms,
                            title: 'Course Info',
                            content: lmsLoaded ?
                                <EventFormCourses />
                                :
                                <></>
                        }] : [],
                ]}
            />
        </>
    )
}
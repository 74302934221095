import { FieldArray, Form, Formik } from "formik"
import { EventStatus, useEventFormContext } from "./event-form"
import * as yup from 'yup'
import { CopyToClipboardIcon } from './copy-to-clipboard-icon'
import { useContext, useState } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { CurrencyField, DatePickerField, ISelectFieldOption, SelectField, TinyMceField } from "./forms"
import dayjs from "dayjs"
import { useRef } from "react"
import { XSquareIcon, BoxArrowUpRight } from "../assets"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { IAddEventModelDocument, IMailBlastTaskResponseDocument } from "../open-api"
import { BRANCH_TIMEZONE_OPTIONS, EventContentType } from "../constants"
import { useMemo } from "react"
import { EventFormCancel } from './event-form-cancel'
import { SERVER_TIME_FORMAT } from "../constants"
import { FormikEffect } from "./formik-effect"
import { getQuarter, uuidv4 } from "../services/helpers"
import { FormikSelectField } from "./forms/formik-select-field"
import { Modal } from "./modal"
import {QRCodeCanvas} from 'qrcode.react';
import { FormikNumberField } from "./forms/formik-number-field"
import { getMaxSeatsOptions } from "./event-content-step2"
import { EventAddToMyCalendar } from "./event-add-to-my-calendar"
import { FormikTextField } from "./forms/formik-text-field"
//import { event } from "jquery"

interface IOEventModel {
    id: string
    eventDate: string
    startTime: string
    endTime: string
    location: string // This is only filled in for series
}

const timeOptions: ISelectFieldOption[] = (() => {
    const options: ISelectFieldOption[] = []

    let startTime = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
    const endTime = startTime.add(24, 'hour');

    let timeOut = 0 // Used to prevent infinite loops. Just to be safe.
    while(startTime < endTime && timeOut < 150){
        const timeStr = startTime.format('h:mm A')
        options.push({
            label: timeStr,
            value: timeStr,
        })

        startTime = startTime.add(15, 'minute');
        ++timeOut
    }
    return options
})()

export const EventFormGeneral = () => {
    const { EventMgmtApi, MailBlastTaskApi, addAlert } = useContext(AppActionContext)!
    const { activeBranches, trainingAreas, currentUser } = useContext(AppStateContext)!
    const { step1Values, eventToEdit, sites, presenters, eventContent, setEventToEdit, inviteBlasts, fetchInviteBlasts } = useEventFormContext()
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [inviteBlast, setInviteBlast] = useState<IMailBlastTaskResponseDocument>()

    const selectedBranch = useMemo(() => {
        const branch = activeBranches.find(o => o.branchId.toString() === step1Values.branchId)
        if (!branch) throw new Error('Cannot progress to Event Details without a selected branch.')
        return branch
    }, [activeBranches, step1Values.branchId])

    const presenterOptions = useMemo(() => {
        return presenters.map(o => ({ label: `${o.name}`, value: o.presenterId.toString() }))
    }, [presenters])

    const eventContentGeneralQuarter = getQuarter(eventContent.month)

    const dateRowSchema = useMemo(() => {
        return yup.object({
            eventDate: yup.string()
                .test(
                    'eventDate',
                    'Events cannot be in the past.',
                    function (value) {
                        if (value && this.parent.startTime) return dayjs(`${value} ${this.parent.startTime}`, 'MM/DD/YYYY h:mm A').isAfter(dayjs())
                        return true
                    }
                ),
            startTime: yup.string()
                .test(
                    'startTime',
                    'Start time must be before the end time.',
                    function (value) {
                        if (this.parent.endTime) {
                            return dayjs(value, 'h:mm A').isBefore(dayjs(this.parent.endTime, 'h:mm A'))
                        } else {
                            return true
                        }
                    })
                .test(
                    'eventDate',
                    'Events cannot be in the past.',
                    function (value) {
                        if (value && this.parent.eventDate) return dayjs(`${this.parent.eventDate} ${value}`, 'MM/DD/YYYY h:mm A').isAfter(dayjs())
                        return true
                    }
                ),
            endTime: yup.string()
                .test(
                    'endTime',
                    'End time must be after the start time.',
                    function (value) {
                        if (this.parent.startTime) {
                            return dayjs(value, 'h:mm A').isAfter(dayjs(this.parent.startTime, 'h:mm A'))
                        } else {
                            return true
                        }
                    }),
        })
    }, [])

    const selectedTrainingArea = trainingAreas?.find(o => o.trainingAreaId.toString() === step1Values.trainingAreaId)
    const selectedSite = sites?.find(o => o.siteId.toString() === step1Values.siteId)

    const getInitTimeZone = () => {
        if (eventToEdit?.eventId){
            // Existing event
            return eventToEdit?.timeZone
        } else {
            // New event
            // Webinars currently only support PST. Eventually we should change this.
            if (eventContent.eventTypeId === EventContentType.Webinar) return 'Pacific Standard Time'

            // Default to the selected community's TZ
            return selectedBranch.timezone
        }
    }

    const activatePressed = useRef(false)

    const initialValues = useMemo(() => {
        return {
            presenterIds: eventToEdit?.presenterIds?.map(o => o.toString()) || (currentUser?.presenterId ? [currentUser.presenterId.toString()] as string[] : [] as string[]),
            multiDayData: eventToEdit?.multiDayData ?
                eventToEdit.multiDayData.map<IOEventModel>(o => ({
                    id: uuidv4(),
                    eventDate: dayjs(o.eventDate).format('MM/DD/YYYY'),
                    startTime: o.startTime || '',
                    endTime: o.endTime || '',
                    location: ''
                }))
                :
                [{
                    id: uuidv4(),
                    eventDate: eventToEdit?.startDateTime ? dayjs(eventToEdit.startDateTime).format('MM/DD/YYYY') : '',
                    startTime: eventToEdit?.startDateTime ? dayjs(eventToEdit.startDateTime).format('h:mm A') : '',
                    endTime: eventToEdit?.endDateTime ? dayjs(eventToEdit.endDateTime).format('h:mm A') : '',
                }] as IOEventModel[],
            isMultiDay: Boolean(eventToEdit?.isMultiDay),
            displayDate: eventToEdit?.displayDate || '',
            seatCount: eventToEdit?.seatCount?.toString() ?? (selectedSite?.maxSeats ?? 0).toString() ?? '',
            seatCost: eventToEdit?.seatCost || 0,
            maxSeatsPerOrg: eventToEdit?.maxSeatsPerOrg?.toString() || '',
            publicEventDetails: eventToEdit?.publicEventDetails || '',
            privateEventDetails: eventToEdit?.privateEventDetails || '',
            invitationOnly: eventContent.invitationOnly ? 'invitation' : (eventToEdit?.invitationOnly ? 'invitation' : 'any'),
            timeZone: getInitTimeZone(),
            webinarUrl: eventToEdit?.webinarUrl || '', // Event-level webinar URL. Not used for Webinar events.
        }
    }, [eventToEdit, selectedSite])

    const [cancelEventModal, showHideCancelEventModal] = useModal()
    const [blastWarningModal, showHideBlastWarningModal] = useModal()
    const [blastWarning2Modal, showHideBlastWarning2Modal] = useModal()
    const [checkInQrCodeModal, showHideCheckInQrCodeModal] = useModal()
    const [addToCalendarModal, showHideAddToCalendarModal] = useModal()

    const readonly = useMemo(() => {
        if (eventToEdit?.isLegacyEvent) return true
        if (eventToEdit?.status === EventStatus.canceled) return true
        if (eventToEdit) {
            if (eventToEdit.multiDayData?.length) {
                const latestDate = eventToEdit.multiDayData.sort((a, b) => {
                    if (dayjs(`${a.eventDate} ${a.startTime}`, 'MM/DD/YYYY h:mm A').isBefore(dayjs(`${b.eventDate} ${b.startTime}`, 'MM/DD/YYYY h:mm A'))) return 1
                    if (dayjs(`${a.eventDate} ${a.startTime}`, 'MM/DD/YYYY h:mm A').isAfter(dayjs(`${b.eventDate} ${b.startTime}`, 'MM/DD/YYYY h:mm A'))) return -1
                    return 0
                })[0]
                return dayjs(`${latestDate.eventDate} ${latestDate.startTime}`, 'MM/DD/YYYY h:mm A').isBefore(dayjs())
            } else if (eventToEdit.startDateTime) {
                return dayjs(eventToEdit.startDateTime).isBefore(dayjs())
            }
        }
        return false
    }, [eventToEdit])

    if (!sites || !presenters) return null

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                    readonly ?
                        yup.object()
                        :
                        yup.object({
                            presenterIds: yup.array().of(yup.string()).min(1, 'Must select at least one presenter.'),
                            multiDayData: yup.array().of(dateRowSchema)
                                .required('Required')
                                .min(1, 'Must include at least one day.')
                                .test(
                                    'multiDayDataWorkshopLength',
                                    'Workshops must be 4 or fewer hours long.',
                                    function (value) {
                                        if (eventContent.eventTypeId === EventContentType.Workshop && !this.parent.isMultiDay && value[0].eventDate?.length && value[0].startTime?.length && value[0].endTime?.length) return dayjs(`${value[0].eventDate} ${value[0].endTime}`, 'MM/DD/YYYY h:mm A').diff(`${value[0].eventDate} ${value[0].startTime}`, 'minutes') <= 240
                                        return true
                                    }
                                )
                                .test({
                                    name: 'noSameDateDays',
                                    test: function (value) {
                                        const map = value.reduce((map, date, index) => {
                                            map.set(
                                                date.eventDate || '',
                                                {
                                                    count: (map.get(date.eventDate || '')?.count || 0) + 1,
                                                    lastErrorIndex: index
                                                })
                                            return map
                                        }, new Map<string, { count: number, lastErrorIndex: number }>())
                                        if (Array.from(map.values()).find(o => o.count > 1)) {
                                            return this.createError({
                                                message: `Cannot have multiple days with the same date.`,
                                                path: `${this.path}.${Array.from(map.values()).find(o => o.count > 1)?.lastErrorIndex}.date`
                                            })
                                        }
                                        return true
                                    }
                                })
                            ,
                            //inviteBlasts: yup.array().of(inviteBlastSchema),
                            displayDate: yup.string()
                                .test(
                                    'displayDate',
                                    'Display date must be before the earliest start time.',
                                    function (value) {
                                        const earliestDate = ([...this.parent.multiDayData] as IOEventModel[]).sort((a, b) => {
                                            if (dayjs(`${a.eventDate} ${a.startTime}`, 'MM/DD/YYYY h:mm A').isBefore(dayjs(`${b.eventDate} ${b.startTime}`, 'MM/DD/YYYY h:mm A'))) return -1
                                            if (dayjs(`${a.eventDate} ${a.startTime}`, 'MM/DD/YYYY h:mm A').isAfter(dayjs(`${b.eventDate} ${b.startTime}`, 'MM/DD/YYYY h:mm A'))) return 1
                                            return 0
                                        })[0]
                                        if (earliestDate && earliestDate.eventDate && earliestDate.startTime) return dayjs(value).isBefore(dayjs(`${earliestDate.eventDate} ${earliestDate.startTime}`, 'MM/DD/YYYY h:mm A'))
                                        return true
                                    }
                                ),
                            seatCount: yup.number().transform((_, value) => {
                                return +value.replace(/,/, '');
                              }).min(1, 'You must have at least one seat available.'),
                            privateEventDetails: yup.string().max(800, 'Private Event Details must be at most ${max} characters. HTML source code is included in the character count. You may view the source code by clicking Tools > Source Code, on this fields toolbar.')
                        })
                }
                onSubmit={async (values) => {
                    const valuesToSave: IAddEventModelDocument = {
                        ...eventToEdit ? eventToEdit : {},
                        ...values,
                        branchId: eventToEdit?.branchId ?? selectedBranch.branchId,
                        isSeries: eventContent.isSeries,
                        startDateTime: (values.multiDayData[0].eventDate ? dayjs(`${values.multiDayData[0].eventDate} ${values.multiDayData[0].startTime}`, 'MM/DD/YYYY h:mm A').format(SERVER_TIME_FORMAT) : null),
                        endDateTime: (values.multiDayData[0].eventDate ? dayjs(`${values.multiDayData[0].eventDate} ${values.multiDayData[0].endTime}`, 'MM/DD/YYYY h:mm A').format(SERVER_TIME_FORMAT) : null),
                        eventContentId: eventContent.eventContentId,
                        invitationOnly: eventContent.invitationOnly ? true : values.invitationOnly == 'invitation',
                        callInNumber: null,
                        trainingAreaId: parseInt(step1Values.trainingAreaId),
                        siteId: parseInt(step1Values.siteId),
                        seatCount: parseInt(values.seatCount),
                        maxSeatsPerOrg: values.maxSeatsPerOrg ? parseInt(values.maxSeatsPerOrg) : null,
                        multiDayData: values.isMultiDay ? values.multiDayData : null,
                        publicEventDetails: values.publicEventDetails.length ? values.publicEventDetails : null,
                        privateEventDetails: values.privateEventDetails.length ? values.privateEventDetails : null,
                        presenterIds: values.presenterIds.map(o => parseInt(o)),
                        groupCoachingTopicId: null,
                        timeZone: values.timeZone,
                        webinarUrl: values.webinarUrl,
                    }

                    if (activatePressed.current && !values.isMultiDay && !(values.multiDayData[0].eventDate && values.multiDayData[0].startTime && values.multiDayData[0].endTime)) {
                        addAlert({
                            id: uuidv4(),
                            title: 'Error',
                            body: 'You must specify a date and time before you can activate this event.',
                        })
                        return
                    }

                    let event = eventToEdit

                    if (eventToEdit) {
                        const { data } = await makeHttpRequestWithUi({
                            request: EventMgmtApi.apiEventMgmtEventEventIdPut(eventToEdit.eventId, valuesToSave),
                            toastSuccessMessage: 'Successfully updated event.',
                            toastErrorMessage: 'Encountered an error saving event.'
                        })
                        event = data
                        setEventToEdit(data)
                    } else {
                        const { data } = await makeHttpRequestWithUi({
                            request: EventMgmtApi.apiEventMgmtEventPost(valuesToSave),
                            toastSuccessMessage: 'Successfully created new event.',
                            toastErrorMessage: 'There was an error creating the event.',
                        })
                        setEventToEdit(data)
                        event = data
                    }

                    if (event) {
                        if (activatePressed.current) {
                            console.log('activatePressed.current')
                            await makeHttpRequestWithUi({
                                request: EventMgmtApi.apiEventMgmtEventEventIdActivatePut(event.eventId),
                                toastSuccessMessage: 'Successfully activated event.',
                                toastErrorMessage: 'Encountered an error activating event.'
                            })
                            setEventToEdit({ ...event, status: EventStatus.activated })
                        }

                        // Did this record just get activated, or has an activated event's date changed?
                        if (activatePressed.current || (eventToEdit?.status === EventStatus.activated && event.startDateTime !== eventToEdit?.startDateTime))
                        {
                            if (inviteBlasts && inviteBlasts.length > 0 && !inviteBlasts?.find(x => x.dSend)) {
                                // No blast has been sent.
                                // Blasts are already sorted in desc order. Get the first one.
                                const upcomingBlast = inviteBlasts[0]
                                if (upcomingBlast && upcomingBlast.scheduleDate && dayjs(upcomingBlast.scheduleDate) > dayjs()) {
                                    const upcomingBlastScheduledDate = dayjs(upcomingBlast.scheduleDate)
                                    const diffInDays_UpcomingBlast = dayjs(event.startDateTime!).diff(upcomingBlastScheduledDate, 'days')
                                    const diffInDays_NowAndStartDate = dayjs(event.startDateTime!).diff(Date.now(), 'days')

                                    if (upcomingBlastScheduledDate > dayjs(event.startDateTime!)) {
                                        // The next scheduled blast occurs after this newly activated event does.
                                        // Suggest to the user they manually add an invite blast.
                                        showHideBlastWarning2Modal(true)
                                    } else if (diffInDays_UpcomingBlast < 14 && diffInDays_NowAndStartDate >=14) {
                                        // The next scheduled blast is scheduled less than 14 days prior to the newly activated event.
                                        // AND this event is scheduled at least 14 days in the future.
                                        // We prefer the first invite blast to go out minimum of 14 days prior. Tell the user that.
                                        setInviteBlast(upcomingBlast)
                                        showHideBlastWarningModal(true)
                                    }
                                }
                            } else if (!inviteBlasts || inviteBlasts.length == 0) {
                                // No blasts exist
                                const diffInDays = dayjs(event.startDateTime!).diff(Date.now(), 'days')
                                console.log('diffInDays', diffInDays)
                                if (diffInDays >= 14) {
                                    // No upcoming blasts and this event occurs at least 14 days in the future.
                                    // As the user if they would like to schedule a blast.
                                    showHideBlastWarningModal(true)
                                } else {
                                    // No upcoming blasts, but this event occurs < 14 days from now.
                                    // Suggest to the user they manually add an invite blast.
                                    showHideBlastWarning2Modal(true)
                                }
                            }
                        }
                    }

                    activatePressed.current = false
                }}
            >
                {formikProps =>
                    <Form className='p-2' style={{ display: 'flex', flexDirection: 'column', minHeight: 0, overflow: 'hidden', height: '100%' }}>

                        <FormikEffect
                            formikProps={formikProps}
                            onChange={(prevValues, nextValues) => {

                                // Automatically change the end time of any days if the start time changes
                                let hasTimeChange = false
                                const nextDayValues = [...nextValues.multiDayData]
                                nextDayValues.forEach((day) => {
                                    const previousDay = prevValues.multiDayData.find(o => o.id === day.id)
                                    if (previousDay && previousDay.startTime !== day.startTime) {
                                        hasTimeChange = true
                                        day.endTime = dayjs(day.startTime, 'h:mm A').add(1, 'hour').format('h:mm A')
                                    }

                                    if (!previousDay || previousDay.startTime !== day.startTime) {
                                        if (getQuarter(dayjs(day.eventDate).month() + 1) !== eventContentGeneralQuarter) {
                                            addAlert({
                                                id: uuidv4(),
                                                title: 'Date falls outside general quarter',
                                                body: 'The date you are choosing falls outside the general quarter of the current workshop. Are you sure you want to do this?',
                                            })
                                        }
                                    }
                                })
                                if (hasTimeChange) formikProps.setFieldValue('multiDayData', nextDayValues)

                                if (prevValues.seatCost < 1 && nextValues.seatCost > 0) 
                                    addAlert({
                                        id: uuidv4(),
                                        title: "Event Payments Notice!",
                                        body: "If this is your first time charging for event seats, please contact Tod Birdsall for an explanation of how it works and some limitations.",
                                    })
                            }}
                        />

                        <div style={{ flex: 1, overflow: 'auto' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                    <div className='mb-2'>
                                        <div><b>Community</b></div>
                                        <div>{activeBranches.find(o => o.branchId.toString() === step1Values.branchId)?.branchName}</div>
                                    </div>
                                    <div className='mb-2'>
                                        <div><b>Training Area</b></div>
                                        <div>{trainingAreas?.find(o => o.trainingAreaId.toString() === step1Values.trainingAreaId)?.trainingArea}</div>
                                    </div>
                                    <div className='mb-2'>
                                        <div><b>Site</b></div>
                                        <div>{sites?.find(o => o.siteId.toString() === step1Values.siteId)?.locationName}</div>
                                    </div>
                                    <div style={{ flex: 1 }} className='mr-2'>
                                        <SelectField disabled={readonly} multiple fieldProps={{ name: 'presenterIds', label: 'Presenter(s)' }} options={presenterOptions} />
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    {eventToEdit?.status === EventStatus.activated && eventToEdit?.publicEventUrl &&
                                        <div className='mb-2'>
                                            <div><b>Registration Page URL</b></div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}><a href={eventToEdit.publicEventUrl} className='mr-2' target='_blank'>{eventToEdit.publicEventUrl}</a> <CopyToClipboardIcon value={eventToEdit.publicEventUrl} /> </div>
                                        </div>
                                    }
                                    {/* {eventToEdit?.checkInUrl &&
                                        <div className='mb-2'>
                                            <div><b>Check-In Page URL</b>
                                            <TooltipInfo tooltipText='Registrants with SSO accounts who navigate to this URL will automatically be flagged as attended.' />
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}><a href={eventToEdit.checkInUrl} className='mr-2' target='_blank'>{eventToEdit.checkInUrl}</a> 
                                            <CopyToClipboardIcon value={eventToEdit.checkInUrl} />
                                            <QrCodeIcon className='cursor-pointer ml-2' onClick={() => showHideCheckInQrCodeModal(true)} title="Generate QR Code" /> </div>
                                        </div>
                                    } */}
                                    {eventContent.courseUrlList && eventContent.courseUrlList.length > 0 &&
                                        <div>
                                            <div><b>Course URL</b></div>
                                            {eventContent.courseUrlList.map(url => <div style={{ display: 'flex', alignItems: 'center' }}><a href={url} className='mr-2' target='_blank'>{url}</a> <CopyToClipboardIcon value={url} /> </div>)}
                                        </div>
                                    }
                                    {eventContent.eventTypeId !== EventContentType.Webinar && selectedTrainingArea?.bVirtual && <div style={{ flex: 1 }} className='mr-2'>
                                        <FormikTextField field={{ name: 'webinarUrl', label: 'Virtual Meeting URL', disabled: readonly }} labelTooltip="Zoom/Teams URL that attendees will use to join your event remotely. This URL will only be included in the 'Add this event to your calendar' as the location. It is not used anywhere else. We also recommend including the link in your Private Event Details." />
                                    </div>}
                                </div>
                            </div>

                            <hr className='my-3' />

                            {/* 
                            20230802 TB - We are replacing event-level MultiDay with EventContent-level Series. Leaving code here, just in case we want to use MultiDay again at some point.
                            <CheckboxField disabled={readonly} fieldProps={{ name: 'isMultiDay', label: 'Multiple Days' }} /> */}

                            <FieldArray
                                name='multiDayData'
                                children={arrayHelpers =>
                                    <div>
                                        {formikProps.values.multiDayData.map((_, index) =>
                                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ flex: 1 }} className='mr-2'>
                                                    <DatePickerField disabled={readonly} fieldProps={{ name: `multiDayData.${index}.eventDate`, label: 'Event Date' }} disableDatepickerThrottle />
                                                </div>
                                                <div style={{ flex: 1 }} className='mr-2'>
                                                    {/* TB - Currently, webinars only support PST. We should change this eventually. */}
                                                    <FormikSelectField field={{ name: 'timeZone', label: 'Time Zone', disabled: eventContent.eventTypeId === EventContentType.Webinar, labelTooltip: "Webinars currently only support the 'America/Los Angeles' time zone." }} options={BRANCH_TIMEZONE_OPTIONS} />
                                                </div>
                                                <div style={{ flex: 1 }} className='mr-2'>
                                                    <FormikSelectField
                                                        field={{ name: `multiDayData.${index}.startTime`, label: 'Start Time', disabled: readonly }}
                                                        options={timeOptions}
                                                    />
                                                </div>
                                                <div style={{ flex: 1 }} className='mr-2'>
                                                    <FormikSelectField
                                                        field={{ name: `multiDayData.${index}.endTime`, label: 'End Time', disabled: readonly }}
                                                        options={timeOptions}
                                                    />
                                                </div>

                                                {formikProps.values.isMultiDay && formikProps.values.multiDayData.length > 1 && !readonly &&
                                                    <XSquareIcon className='error-color-hover cursor-pointer ml-2' onClick={() => arrayHelpers.remove(index)} />
                                                }
                                            </div>
                                        )}

                                        <div className='invalid-feedback' style={{ display: 'block' }}>{typeof formikProps.errors.multiDayData === 'string' && formikProps.errors.multiDayData}</div>

                                        {formikProps.values.isMultiDay &&
                                            <button
                                                className='btn btn-primary btn-sm'
                                                type='button'
                                                onClick={() => arrayHelpers.push({ id: uuidv4(), date: formikProps.values.multiDayData.slice(-1)[0]?.eventDate?.length ? dayjs(formikProps.values.multiDayData.slice(-1)[0]?.eventDate).add(1, 'day').format('MM/DD/YYYY') : '', startTime: '', endTime: '' })}
                                                disabled={readonly}
                                            >
                                                Add Day
                                            </button>
                                        }
                                    </div>
                                }
                            />

                            <hr className='my-3' />

                            <div className="d-flex">
                                <div style={{flex: 1}} className='mr-2'>
                                    <DatePickerField disabled={readonly}
                                        fieldProps={{
                                            name: 'displayDate',
                                            label: 'Display Date (optional)',
                                            placeholder: 'Select a date...',
                                            labeltooltip: "Use to hide this event until the specified date.<br />If specified, event will not be visible from the Ministry Portal and won't display in the newsletter until this date is reached."
                                        }}
                                        disableDatepickerThrottle
                                    />
                                </div>
                                { 
                                    // 20230802 TB - invitationOnly can be set upon first creation. However, if this turns out to be a series OR the EventContent record's invitationOnly value == TRUE,
                                    // invitationOnly must be controlled via the EventContent UI form, since all sibling events must have the same invitationOnly.
                                    !eventContent?.invitationOnly &&
                                    <div style={{flex: 1}} className='mr-2'>
                                        <FormikSelectField
                                            field={{ 
                                                name: 'invitationOnly', 
                                                label: 'Which MI Ministries can register?', 
                                                labelTooltip: "If 'Invitation Only', this event will only be accessible to those whom you send the 'Registration Page URL' to.",
                                                disabled: readonly  }}
                                            options={[
                                                { value: 'any', label: 'Any MI Ministry' },
                                                { value: 'invitation', label: 'By Invitation Only' }
                                            ]}
                                        />
                                    </div>
                                }
                                <div style={{flex: 1}} className='mr-2'>
                                    <FormikNumberField field={{ 
                                        name: 'seatCount', 
                                        label: `Seat Count ${selectedSite?.maxSeats ? `(site max ${selectedSite.maxSeats})` : ''}`,
                                        labelTooltip: eventContent?.isSeries ? 'Edit at the Event Content level.' : '',
                                        disabled: Boolean(readonly || eventContent?.isSeries) }} />
                                </div>
                                <div style={{flex: 1}} className='mr-2'>
                                    <CurrencyField disabled={Boolean(readonly || eventContent?.isSeries)} fieldProps={{ name: 'seatCost', label: 'Seat Cost' }} />
                                </div>
                                <div style={{flex: 1}}>
                                <FormikSelectField
                                    field={{
                                        name: 'maxSeatsPerOrg', 
                                        label: 'Max Seats Per Org', 
                                        labelTooltip: 'Would you like to limit the number of seats that a single org can reserve for this event?',
                                        disabled: Boolean(readonly || eventContent?.isSeries)}}
                                    options={getMaxSeatsOptions()}
                                 />
                                </div>
                            </div>

                            <TinyMceField disabled={readonly} fieldProps={{ 
                                name: 'publicEventDetails', 
                                label: 'Public Event Details',
                                labeltooltip: "Visible to anyone looking at the event's details." }} />
                            <TinyMceField disabled={readonly} fieldProps={{ 
                                name: 'privateEventDetails', 
                                label: 'Private Event Details',
                                labeltooltip: "Only shared with users after they register. Useful for sharing video conferencing information (e.g., Zoom link)." }} />
                        </div>

                        <div style={{ display: 'flex' }} className='mt-2'>

                            {eventToEdit?.status !== EventStatus["canceled"] &&
                                <button className='btn btn-primary mr-2' style={{ minWidth: 100 }} type='submit' disabled={readonly} >Save</button>
                            }

                            {eventToEdit?.publicEventUrl && eventToEdit.status === EventStatus.draft && !readonly &&
                                <a
                                    href={eventToEdit.publicEventUrl}
                                    className='btn btn-secondary mr-2'
                                    style={{ minWidth: 100 }}
                                    type='button'
                                    target='_blank'
                                >
                                    Preview
                                </a>
                            }

                            {eventToEdit?.status === EventStatus.draft &&
                                <button
                                    type='button'
                                    className='btn btn-success mr-2'
                                    style={{ minWidth: 100 }}
                                    onClick={() => {
                                        activatePressed.current = true
                                        formikProps.submitForm()
                                    }}
                                    disabled={readonly}
                                >
                                    Activate Event
                                </button>
                            }

                            {eventToEdit && eventToEdit.status === EventStatus.activated &&
                                <>
                                    <button
                                        className='btn btn-warning mr-2'
                                        style={{ minWidth: 100 }}
                                        onClick={() => showHideCancelEventModal(true)}
                                        type='button'
                                        disabled={readonly}
                                    >
                                        Cancel Event
                                    </button>
                                    <EventFormCancel 
                                        modalProps={cancelEventModal}
                                        eventToEdit={eventToEdit}
                                        setEventToEdit={setEventToEdit} />

                                    <button
                                        className='btn btn-secondary mr-2'
                                        style={{ minWidth: 100 }}
                                        onClick={() => showHideAddToCalendarModal(true)}
                                        type='button'
                                        disabled={readonly}
                                    >
                                        Add To My Calendar
                                    </button>
                                    <EventAddToMyCalendar 
                                        modalProps={addToCalendarModal}
                                        eventContentTitle={eventToEdit.eventContent.title}
                                        eventId={eventToEdit.eventId}
                                        startTime={dayjs(eventToEdit.startDateTime?.toString()).format('MM/DD/YYYY hh:mm a')}
                                        timezone={eventToEdit.timezoneGeneric || ''} />
                                </>
                            }
                        </div>
                    </Form>
                }
            </Formik>
            <Modal
				{...blastWarningModal}
				modalTitle={`Invite Blast Warning`}
				_onModalHidden={() => {
					setInviteBlast(undefined)
				}}
				size='lg'
                dismissible={false}
                footer={
                    <>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideBlastWarningModal(false)}>No</button>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={async () => {
                                if (inviteBlast) {
                                    // Delete the existing blast
                                    await makeHttpRequestWithUi({
                                        request: MailBlastTaskApi.apiMailBlastTaskEventContentEventContentIdDeleteEventInviteBlastDelete(inviteBlast.eventContentId!, inviteBlast.mailBlastId!),
                                        disableSuccessToast: true,
                                        toastErrorMessage: 'Encountered an error removing the organization.',
                                    })
                                }

                                if (eventToEdit) {
                                    // Create a new blast
                                    await makeHttpRequestWithUi({
                                        request: MailBlastTaskApi.apiMailBlastTaskEventContentEventContentIdScheduleEventInviteBlastPost(eventToEdit.eventContentId, {
                                            branchId: eventToEdit.branchId!,
                                            sendAt: [ dayjs(eventToEdit?.startDateTime!).startOf('hour').subtract(2, 'weeks').format(SERVER_TIME_FORMAT) ]
                                        }),
                                        disableSuccessToast: true,
                                        toastErrorMessage: 'Encountered an error scheduling invite blast.'
                                    })
                                    fetchInviteBlasts()
                                    showHideBlastWarningModal(false)
                                }
                            }}
                        >
                            Yes
                        </button>
                    </>
                }
			>
                {inviteBlast ?
                    <p>
                        This event is scheduled for {dayjs(eventToEdit?.startDateTime!).format('MM/DD/YYYY')}, but your next upcoming Invite Blast is scheduled for {dayjs(inviteBlast.scheduleDate!).format('MM/DD/YYYY')}.
                        {/* Those dates are only {dayjs(eventToEdit?.startDateTime!).diff(dayjs(inviteBlast.scheduleDate!), 'days')} {`day${dayjs(inviteBlast.scheduleDate!).diff(dayjs(eventToEdit?.startDateTime!), 'days') === 1 ? '' : 's'}`} apart. */}
                        <br />
                        <br />
                        We recommend that at least one invite blast be sent <strong>14 days prior</strong> to your first event in this group.
                        <br />
                        <br />
                        <strong>Would you like to change your next upcoming scheduled invite blast
                        from the current date of {dayjs(inviteBlast.scheduleDate!).format('MM/DD/YYYY')} to {dayjs(eventToEdit?.startDateTime!).subtract(2, 'weeks').format('MM/DD/YYYY')}?</strong>
                        <br />
                        <br />
                        You can always change your upcoming invite blasts anytime before their scheduled date.
                    </p>
                    :
                    <p>
                        There are currently no Invite Blasts scheduled for this event group in your community.
                        <br />
                        <br />
                        We recommend that at least one invite blast be sent <strong>14 days prior</strong> to your first event in this group.
                        <br />
                        <br />
                        <strong>Would you like to schedule an Invite Blast for {dayjs(eventToEdit?.startDateTime!).subtract(2, 'weeks').format('MM/DD/YYYY')}?</strong>
                        <br />
                        <br />
                        You can always change your upcoming invite blasts anytime before their scheduled date.
                    </p>
                }
				
			</Modal>

            <Modal
				{...blastWarning2Modal}
				modalTitle={`Invite Blast Warning`}
				size='lg'
                dismissible={false}
                footer={
                    <>
                        <button type='button' className='btn btn-primary' onClick={() => showHideBlastWarning2Modal(false)}>Ok</button>
                    </>
                }
			>
                <p>
                    There are currently no Invite Blasts scheduled for this event group in your community or the ones that are scheduled occur after this newly activated event.
                    <br />
                    <br />
                    Please consider updating your scheduled invite blasts for this group by clicking the corresponding &quot;Manage Invite Blasts&quot; button on the <a href={`/event-management`} target="_blank">Event Management <BoxArrowUpRight /></a> page.
                </p>
			</Modal>

            <Modal
				{...checkInQrCodeModal}
				modalTitle={`Check-In QR Code`}
				size='lg'
			>
                {eventToEdit?.checkInUrl &&
                    <>
                        <p>
                            Here is a QR Code representation of the check-in URL (<a href={eventToEdit?.checkInUrl}>{eventToEdit?.checkInUrl}</a>). <br />
                            You may copy and paste the QR Code image below into your PowerPoint slides so that your attendees can scan it with their mobile devices as they are being seated.
                        </p>
                        <p>
                            What happens when a someone goes to the check-in URL? <br />
                        </p>
                        <ul>
                            <li>If they already have an SSO account attached to a registered ministry, they will be added as a registrant to this event (as needed) and be flagged as attended.</li>
                            <li>If they do not have an SSO account, they will be directed to create one.</li>
                            <li>If their SSO account is not attached to a registered ministry, they will be directed to the ministry registration process.</li>
                        </ul>
                        <div className="d-flex justify-content-center" ><QRCodeCanvas value={eventToEdit?.checkInUrl} /></div>
                    </>
                }
				
			</Modal>
        </>
    )
}



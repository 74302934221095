import { EventEvals2 } from "./event-evals2"
import { useEventFormContext } from "./event-form"

export const EventFormEvals = () => {

    const { eventToEdit } = useEventFormContext()

    return (
        <>
             <EventEvals2 eventId={eventToEdit?.eventId} />
        </>
    )
}